@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

._ca{
  cursor: pointer;
  position: absolute;
  top: 35px;
  left: 40px;
  font-size: 1vw !important;
}

.App-header {
  position: static;
  background: rgba(0,0,0,0.8);
  height: 12vmin;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 450px) and (max-height: 1005px) {
  .App-header {
    height: 8vmin;
    min-height: 7vh;
    padding: 5px;
  }

  .App-logo {
    height: 25vmin; /* Further reduce logo size for small screens */
  }

  ._ca {
    top: 10px;
    left: 15%;
    font-size: 2vw !important; /* Increased font size for visibility */
  }
  ._coin{
    margin-right: 50%;
    margin-top: 4vw !important;
    font-size: 4vw !important;
  }

  ._play{
    top: 8vw !important;
    right: 15vw !important;
    padding: 6px 8px !important;
  }
  .carousel-container{
    margin-left: -30% !important;
  }

  .frame{
    width: 15vw !important; /* Задайте бажану ширину */
    height: 28vw !important; /* Задайте бажану висоту */;
  }
  ._carousel_text{
    font-size: 6vw !important;
  }
  ._sotials img{
    width: 15vw !important;
  }
}

@media only screen and (max-width: 350px) {
  .App-header {
    height: 6vmin;
    min-height: 5vh;
    padding: 5px;
  }

  .App-logo {
    height: 20vmin; /* Smaller logo for very small screens */
  }

  ._ca {
    top: 8px;
    left: 10px;
    font-size: 2vw !important; /* Further increase font size */
  }
}